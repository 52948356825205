.App {
  min-height: 100vh;
  background: linear-gradient(135deg, #ff1a1a 0%, #ff4d4d 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Helvetica Neue', sans-serif;
  position: relative;
  overflow: hidden;
}

.App::before {
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffffff' fill-opacity='0.05'/%3E%3C/svg%3E");
  animation: backgroundScroll 60s linear infinite;
  opacity: 0.5;
}

.landing-container {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.8s ease-out;
  padding: 2rem;
  width: 100%;
  max-width: 1200px;
}

.landing-container.visible {
  opacity: 1;
  transform: translateY(0);
}

.logo-container {
  text-align: center;
  margin-bottom: 3rem;
  animation: float 3s ease-in-out infinite;
}

.yelp-text {
  font-size: 4rem;
  font-weight: bold;
  color: white;
}

.eu-text {
  font-size: 4rem;
  font-weight: bold;
  color: #FFD700;
  margin-left: 0.5rem;
}

.coming-soon-container {
  text-align: center;
  margin-bottom: 4rem;
}

.coming-soon {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.city-showcase {
  margin: 2rem 0;
}

.discovering-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.city-text {
  color: white;
  font-size: 2rem;
  font-weight: bold;
  transition: all 0.3s ease;
  animation: fadeInOut 2s infinite;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
}

.feature-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-10px);
}

.feature-content {
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
}

.newsletter-container {
  text-align: center;
  margin-top: 4rem;
}

.newsletter-container h2 {
  color: white;
  margin-bottom: 1.5rem;
}

.input-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  max-width: 500px;
  margin: 0 auto;
}

input[type="email"] {
  padding: 1rem;
  border: none;
  border-radius: 25px;
  width: 60%;
  font-size: 1rem;
}

.notify-btn {
  padding: 1rem 2rem;
  border: none;
  border-radius: 25px;
  background: #FFD700;
  color: #ff1a1a;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.notify-btn:hover {
  transform: scale(1.05);
  background: #fff;
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translateY(10px); }
  50% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-10px); }
}

@media (max-width: 768px) {
  .input-container {
    flex-direction: column;
    padding: 0 2rem;
  }
  
  input[type="email"] {
    width: 100%;
  }
  
  .yelp-text, .eu-text {
    font-size: 3rem;
  }
}

.stats-container {
  display: flex;
  justify-content: space-around;
  margin: 3rem 0;
  flex-wrap: wrap;
  gap: 2rem;
}

.stat-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  text-align: center;
  min-width: 200px;
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-10px);
}

.stat-number {
  font-size: 2.5rem;
  font-weight: bold;
  color: #FFD700;
  margin-bottom: 0.5rem;
}

.stat-text {
  color: white;
  font-size: 1.2rem;
}

@keyframes backgroundScroll {
  0% { transform: translate(0, 0); }
  100% { transform: translate(-50%, -50%); }
}

/* Add responsive design improvements */
@media (max-width: 768px) {
  .stats-container {
    flex-direction: column;
    align-items: center;
  }
  
  .stat-card {
    width: 100%;
    max-width: 300px;
  }
}
